import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['addSubmit', 'returnSubmit']

  connect () {
    // If subject type predetermined, enable form submission
    if (this.element.dataset.subjectType) {
      this.enableSubmit()
    }
  }

  enableSubmit () {
    this.addSubmitTarget.disabled = false
    this.returnSubmitTarget.disabled = false
  }
}
