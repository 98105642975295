import Appsignal from '@appsignal/javascript'

// Call at page initialization to initialize Appsignal logging
export function initLogging () {
  const globals = window.provenance
  if (!globals) return
  if (
    typeof globals !== 'undefined' ||
    typeof globals.appsignalApiKey !== 'undefined' ||
    typeof globals.applicationEnvironment !== 'undefined'
  ) {
    const appsignal = new Appsignal({
      key: globals.appsignalApiKey,
      revision: globals.revision
    })
    globals.appsignal = appsignal
  }
}

// Log an error to Appsignal only
export function internalLog (err) {
  const appsignal = window.provenance.appsignal
  if (appsignal) {
    appsignal.sendError(err)
  }
}

// Log an error to Appsignal and the JS console
export function log (err) {
  // eslint-disable-next-line no-console
  console.log(err.message)

  internalLog(err)
}
