import { Controller } from '@hotwired/stimulus'

import * as analytics from '../../utils/analytics'

export default class extends Controller {
  static values = {
    userData: Object,
    params: Object
  }

  click () {
    const properties = { ...this.userDataValue, ...this.paramsValue }

    analytics.trackInApp('button_clicked', properties)
  }
}
