import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['recyclable', 'nonRecyclable', 'materialInput']
  static values = {
    recyclabilityData: Array
  }

  connect () {
    this.displayRecyclability(this.materialInputTarget.value)
  }

  setRecyclability (event) {
    const materialId = event.target.value
    this.displayRecyclability(materialId)
  }

  displayRecyclability (materialId) {
    if (materialId === '') {
      this.recyclableTarget.hidden = true
      this.nonRecyclableTarget.hidden = true
      return
    }

    const materialRecyclability = this.recyclabilityDataValue.find(e => e.id === Number(materialId))
    const recyclable = materialRecyclability.kerbsideRecyclable

    if (recyclable) {
      this.recyclableTarget.hidden = false
      this.nonRecyclableTarget.hidden = true
    } else {
      this.recyclableTarget.hidden = true
      this.nonRecyclableTarget.hidden = false
    }
  }
}
