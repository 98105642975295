import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

import * as analytics from '../../utils/analytics'

export default class extends Controller {
  static values = { template: String }

  connect () {
    const options = {
      theme: 'light',
      delay: [0, 500],
      trigger: 'click mouseenter',
      maxWidth: 368,
      allowHTML: true,
      onShown (instance) {
        const { tippyContent, tipTemplateValue } = instance.reference.dataset
        analytics.track('Popup tip opened', {
          title: (tippyContent || tipTemplateValue) // could be either inline or template
            .replace(/(<([^>]+)>)/gi, '') // strip HTML tags
            .split('\n')[0], // grab first text before newline
          href: window.location.href
        })
        analytics.track('popupTip_opened', {
          object: 'popupTip',
          action: 'opened',
          title: (tippyContent || tipTemplateValue)
            .replace(/(<([^>]+)>)/gi, '') // strip HTML tags
            .split('\n')[0]
        }, { adapter: 'segment' })
      }
    }

    if (this.hasTemplateValue) {
      options.content = (reference) => {
        const id = reference.getAttribute('data-tip-template-value')
        const template = document.getElementById(id)
        if (!template) {
          // Hint: if you see this warning, make sure the template is rendered in app/components/tips_component.html.erb
          console.warn(`Undefined tip with id="${id}"`)
          return '' // will display an empty tip to the user
        }
        return template.innerHTML
      }
    }

    tippy(this.element, options)
  }
}
