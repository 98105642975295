import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['addItem', 'template']

  addAssociation (event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/TEMPLATE/g, new Date().valueOf())
    this.addItemTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeAssociation (event) {
    event.preventDefault()

    const item = event.target.closest('.NestedFormFields')
    item.querySelector("input[name*='_destroy']").value = 'true'
    item.style.display = 'none'
  }
}
