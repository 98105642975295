import { Controller } from '@hotwired/stimulus'

// Simulates radio button behaviour between `formField` targets
export default class extends Controller {
  static targets = ['formField']

  toggle (event) {
    this.formFieldTargets.forEach((field) => {
      field.value = false // Set all to false
    })
    event.target.previousElementSibling.value = true // Set sibling (hidden input) of the clicked element to true
  }
}
