import { Application } from '@hotwired/stimulus'
import { installErrorHandler } from '@appsignal/stimulus'

export function initStimulus(controllerDefinitions) {
  const application = Application.start();
  // This loads controllers based on the structure defined by the [esbuild stimulus plugin](https://github.com/zombiezen/esbuild-plugin-stimulus). Clients
  // should either use the plugin or contruct a similar array manually.
  application.load(controllerDefinitions);

  const appsignal = window.provenance.appsignal
  if (appsignal) {
    installErrorHandler(appsignal, application)
  }
}
