import { Controller } from '@hotwired/stimulus'
import { assignParamsObject } from '../../utils/urlParams.js'

export default class extends Controller {
  static targets = ['checkbox', 'option']
  static values = {
    params: Object
  }

  toggleSelect () {
    if (this.optionTarget.classList.contains('selected')) {
      this.toggleOption(false)
      const params = this.removeFromUrlParams()
      this.buildAndReplaceUrl(params)
    } else {
      this.toggleOption(true)
      const params = this.addToUrlParams()
      this.buildAndReplaceUrl(params)
    }
  }

  toggleOption (selected) {
    if (selected) {
      this.optionTarget.classList.add('selected')
      this.checkboxTarget.checked = true
    } else {
      this.optionTarget.classList.remove('selected')
      this.checkboxTarget.checked = false
    }
  }

  addToUrlParams () {
    const params = new URL(window.location.href).searchParams

    params.append(this.paramsValue.name, this.paramsValue.value)

    return params
  }

  removeFromUrlParams () {
    const params = new URL(window.location.href).searchParams

    const param = params.get(this.paramsValue.name)

    // If param key has more than one value
    if (param.split(',').filter((p) => p).length > 1) {
      params.set(this.paramsValue.name, param.replace(this.paramsValue.value, ''))
    } else {
      params.delete(this.paramsValue.name)
    }

    return params
  }

  buildAndReplaceUrl (params) {
    const paramsObj = assignParamsObject(params)

    const newUrl = new URL(`${window.location.origin}${window.location.pathname}`)

    for (const [key, val] of Object.entries(paramsObj)) {
      newUrl.searchParams.append(key, val)
    }

    history.replaceState(paramsObj, '', newUrl)
  }
}
