import { AnalyticsBrowser } from '@segment/analytics-next'

import { internalLog, log } from './logger'

// IP addresses are anonymised in Segment calls (identify and track) to avoid any PII being captured
// See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#anonymizing-ip for info.

let segmentAnalytics
if (window.provenance && window.provenance.segmentWriteKey) {
  segmentAnalytics = AnalyticsBrowser.load({ writeKey: window.provenance.segmentWriteKey })
  // The Segment user is defined as an account identifier
  console.debug('Init Segment', window.provenance.segmentWriteKey)
  if (window.provenance.segmentUserId) {
    segmentAnalytics.identify(window.provenance.segmentUserId, {}, { context: { ip: '0.0.0.0' } })
  }
}

const postEventToGTM = (event, properties) => {
  // console.log('Track event: GTM', event, properties)

  properties.eventCategory = properties.eventCategory ? `provenance:${properties.eventCategory}` : event

  try {
    // Old-style IFrame tracking
    if (window.xprops && window.xprops.track) {
      window.xprops.track(event, properties)
    }

    const eventData = properties
    eventData.event = event

    // New-style IFrame tracking
    if (window.xprops && window.xprops.onChildEvent) {
      const event = {
        name: 'analytics_event',
        data: {
          details: eventData
        }
      }
      window.xprops.onChildEvent(event)
    }

    // Also push to the data layer of the iFrame, for internal capture
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventData)
  } catch (error) {
    log(error)
  }
}

const postEventToSegment = (event, properties) => {
  if (window.provenance.segmentWriteKey) {
    // console.log('Track event: Segment', event, properties)
    segmentAnalytics.track(event, properties, { context: { ip: '0.0.0.0' } })
  }
}

// For tracking events on platform embedded content
const track = (event, properties, options = {}) => {
  const adapter = options.adapter || 'gtm'
  // Prefix all event names with "provenance:"
  event = `provenance:${event}`
  const undefinedProvEventProps = []
  for (const property in properties) {
    if (properties[property] === undefined || properties[property] === '') {
      undefinedProvEventProps.push('Property ' + property + ' undefined')
    }
  }
  if (undefinedProvEventProps.length > 0) {
    internalLog(undefinedProvEventProps, event, properties, adapter)
  }

  // TODO: add adapters to gtm track calls and segment tracking (plus adapter) to all track calls
  if (adapter === 'segment') {
    postEventToSegment(event, properties)
  } else {
    postEventToGTM(event, properties)
  }
}

const trackInApp = (event, properties) => {
  postEventToSegment(event, properties)
}

export { track, trackInApp, segmentAnalytics }
