import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static get targets () {
    return ['input', 'label', 'labelInput', 'chevron', 'children']
  }

  connect () {
    this.updateInputs()
  }

  updateInputs (event) {
    this.checkInputs()
    this.setSelectAll(this.inputsChecked)

    if (event) {
      const checkedDetails = {
        checked: event.target.checked,
        id: event.target.attributes['data-checked-param'].value
      }
      this.selectAllChildren(checkedDetails)
    }
  }

  checkInputs () {
    this.inputsChecked = true
    this.inputTargets.forEach(input => {
      if (!input.checked) {
        this.inputsChecked = false
      }
    })
  }

  setSelectAll (checked) {
    this.setLabel(checked)
    this.setLabelInput(checked)
  }

  setLabelInput (checked) {
    // Some usages of the resource-list-controller do not include label as a target
    if (this.hasLabelInputTarget) {
      if (checked) {
        this.labelInputTarget.checked = true
      } else {
        this.labelInputTarget.checked = false
      }
    }
  }

  setLabel (checked) {
    // Some usages of the resource-list-controller do not include label as a target
    if (this.hasLabelTarget) {
      if (checked) {
        this.labelTarget.textContent = 'Remove all'
      } else {
        this.labelTarget.textContent = 'Select all'
      }
    }
  }

  selectAll (event) {
    event.preventDefault()
    event.stopPropagation()
    this.checkInputs()
    this.inputTargets.forEach(input => {
      const parent = input.parentElement
      // TODO: Review approach
      if (!parent.classList.contains('is-locked')) {
        // eslint-disable-next-line no-param-reassign
        input.checked = !this.inputsChecked
      }
    })
    this.updateInputs()
  }

  toggleChildren (e) {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.open = !e.currentTarget.open

    if (e.currentTarget.open) {
      e.currentTarget.classList.add('child-chevron--open')
    } else {
      e.currentTarget.classList.remove('child-chevron--open')
    }

    const toggleDetails = {
      detail: {
        open: (e.currentTarget.open),
        id: e.currentTarget.attributes['data-parent-id-param'].value
      }
    }
    this.showChildren(toggleDetails)
  }

  showChildren ({ detail: { open, id } = {} }) {
    this.childrenTargets.forEach((element, index) => {
      const parentId = element.attributes['data-parent-id-param'].value
      if (parentId === id) {
        if (open) {
          element.classList.add('show-children')
        } else {
          element.classList.remove('show-children')
        }
      }
    })
  }

  selectAllChildren ({ checked, id }) {
    this.childrenTargets.forEach((element, index) => {
      const parentId = element.attributes['data-parent-id-param'].value
      if (parentId === id) {
        element.children[0].children[0].checked = checked
      }
    })
  }
}
