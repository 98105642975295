import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'button', 'textarea']
  static values = { hasIngredients: Boolean }

  connect () {
    this.buttonTarget.classList.add('Button__disabled')

    if (this.hasIngredientsValue && this.checkboxTarget.checked) {
      this.buttonTarget.classList.toggle('Button__disabled', false)
    } else if (this.checkboxTarget.checked) {
      this.buttonTarget.classList.toggle('Button__disabled', false)
    }
  }

  toggleIngredients() {
    this.buttonTarget.classList.toggle('Button__disabled', !(this.hasIngredientsValue && this.checkboxTarget.checked))
  }

  toggleStatementOfTransparency() {
    this.buttonTarget.classList.toggle('Button__disabled', !(this.checkboxTarget.checked && this.textareaTarget.value.length > 0))
  }
}
