import { Controller } from '@hotwired/stimulus'
import MicroModal from 'micromodal'

/**
 * Manages the triggering of alert dialogs for warnings and confirmations.
 */
export default class AlertTriggerController extends Controller {
  delete (event) {
    event.preventDefault()
    event.stopPropagation()

    const heading = this.element.dataset.alertHeading
    const url = this.element.dataset.alertUrl
    const note = this.element.dataset.alertNote || ''
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const action = `
      <form class="button_to" method="post" action="${url}">
        <input type="hidden" name="_method" value="delete">
        <input class="Action Action--alert" data-action="alert#hide" type="submit" value="Delete">
        <input type="hidden" name="authenticity_token" value="${token}">
      </form>
    `

    const modal = this.buildConfirmDialog('alert', heading, note, action)
    this.showModal(modal)
  }

  warning (event) {
    event.preventDefault()
    event.stopPropagation()

    const heading = this.element.dataset.alertHeading
    const url = this.element.dataset.alertUrl
    const note = this.element.dataset.alertNote || ''
    const buttonText = this.element.dataset.alertButtonText || 'Continue'
    const method = this.element.dataset.method || 'post'
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const action = `
      <form class="button_to" method="${method}" action="${url}">
        <input class="Action Action--alert" data-action="alert#hide" type="submit" value="${buttonText}">
        <input type="hidden" name="authenticity_token" value="${token}">
      </form>
    `

    const modal = this.buildConfirmDialog('alert', heading, note, action)
    this.showModal(modal)
  }

  update (event) {
    event.preventDefault()
    event.stopPropagation()

    const heading = this.element.dataset.alertHeading
    const url = this.element.dataset.alertUrl
    const note = this.element.dataset.alertNote || ''
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const action = `
      <form class="button_to" method="get" action="${url}">
        <input class="Action Action--primary" data-action="alert#hide" type="submit" value="Copy Submission">
        <input type="hidden" name="authenticity_token" value="${token}">
      </form>
    `
    const modal = this.buildConfirmDialog('alert', heading, note, action)
    this.showModal(modal)
  }

  editActivatedProducts (event) {
    event.preventDefault()
    event.stopPropagation()

    const heading = this.element.dataset.alertHeading
    const url = this.element.dataset.alertUrl
    const note = this.element.dataset.alertNote
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const action = `
      <form class="button_to" method="get" action="${url}">
        <input class="Action Action--primary" data-action="alert#hide" type="submit" value="Continue">
        <input type="hidden" name="authenticity_token" value="${token}">
      </form>
    `
    const modal = this.buildConfirmDialog('alert', heading, note, action)
    this.showModal(modal)
  }

  archive (event) {
    event.preventDefault()
    event.stopPropagation()

    const heading = this.element.dataset.alertHeading
    const url = this.element.dataset.alertUrl
    const note = this.element.dataset.alertNote || ''
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const action = `
      <form class="button_to" method="get" action="${url}">
        <input class="Action Action--alert" data-action="alert#hide" type="submit" value="Archive Submission">
        <input type="hidden" name="authenticity_token" value="${token}">
      </form>
    `

    const modal = this.buildConfirmDialog('alert', heading, note, action)
    this.showModal(modal)
  }

  contact (event) {
    event.preventDefault()
    event.stopPropagation()

    const heading = this.element.dataset.alertHeading
    const note = this.element.dataset.alertNote || ''
    const action = '<button class="Action Action--primary" data-action="alert#submitContact">Contact us</button>'

    const modal = this.buildConfirmDialog('alert', heading, note, action)
    this.showModal(modal)
  }

  toggle (event) {
    // Only show dialog if disabling the toggle
    if (!event.target.checked) {
      // Reset the state so that confirm dialog can disable if confirmed
      /* eslint-disable no-param-reassign */
      event.target.checked = !event.target.checked
      const cta = this.element.dataset.alertCta
      const action = `<button class="Action Action--primary Action--alert" data-action="alert#submitToggle" data-alert-checkbox-id="${event.target.id}">${cta}</button>`
      this.initBasicDialog(event, action)
    }
    this.getSortLanguagesController().updateDefaultLabel()
  }

  getSortLanguagesController () {
    const element = document.getElementById('sortLanguages')
    return this.application.getControllerForElementAndIdentifier(
      element,
      'sort-languages'
    )
  }

  initBasicDialog (event, action) {
    event.preventDefault()
    event.stopPropagation()

    const heading = this.element.dataset.alertHeading
    const note = this.element.dataset.alertNote || ''
    const modal = this.buildConfirmDialog('alert', heading, note, action)
    this.showModal(modal)
  }

  buildConfirmDialog (controller, heading, note, action) {
    const modalHTML =
    `
    <div id="alert-modal" class="ProvenanceModal ProvenanceModal--confirmVariant APP" aria-hidden="true" data-controller="alert">
      <div class="ProvenanceModal-backdrop" tabindex="-1" data-micromodal-close>
        <div class="ProvenanceModal-dialog" role="dialog" aria-modal="true" aria-labelledby="alert-modal">
          <div class="Modal-heading">
              <p class="Heading u-textCenter">${heading}</p>
            </div>
            <div class="Modal-note">
              <p class="Text-bodySansSmall u-textCenter mt-2">${note}</p>
            </div>
            <div class="Modal-actions u-textCenter">
              <div class="Modal-action Modal-action--cancel">
                <button class="Action Action--text Action--inline" data-action="${controller}#cancel">Cancel</button>
              </div>
              <div class="Modal-action Modal-action--confirm">
                ${action}
              </div>
          </div>
        </div>
      </div>
    </div>
    `
    const template = document.createElement('template')
    template.innerHTML = modalHTML
    return template.content.firstElementChild.cloneNode(true)
  }

  showModal (modal) {
    document.body.appendChild(modal)

    MicroModal.show('alert-modal', {
      onClose: modal => {
        modal.remove()
      },
      disableScroll: true
    })
  }
}
