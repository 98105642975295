import { Controller } from '@hotwired/stimulus'
import { assignParamsObject } from '../../utils/urlParams.js'
import * as analytics from '../../utils/analytics.js'

export default class extends Controller {
  static targets = ['filterOptions', 'overlay']
  static values = {
    userData: Object,
    name: String
  }

  open (e) {
    e.preventDefault()
    this.filterOptionsTarget.hidden = false
    this.overlayTarget.classList.add('Overlayer--visible')
  }

  close () {
    this.filterOptionsTarget.hidden = true
    this.overlayTarget.classList.remove('Overlayer--visible')
  }

  apply (e) {
    e.preventDefault()
    this.trackAnalytics()
    window.location.reload()
  }

  trackAnalytics () {
    const params = new URL(window.location.href).searchParams
    const paramsObj = assignParamsObject(params)
    const properties = { name: this.nameValue, value: paramsObj, ...this.userDataValue }

    analytics.trackInApp('button_clicked', properties)
  }
}
