// app/javascript/controllers/dialog_controller.js
import { Controller } from '@hotwired/stimulus';

// Primer dialog generally requires user input: https://primer.style/components/dialog/rails/alpha
// However, the dialogs used on the organizations/retailer_product_imports/new and organizations/retailer_claim_imports/new
// customer onboarding views are designed to show for a few seconds post submission and then redirect.
// Therefore this controller is required to control the primer dialog
export default class extends Controller {
  static targets = ["form", "dialog"];

  connect() {
    this.formTarget.addEventListener("submit", this.showDialog.bind(this));
  }

  showDialog() {
    this.dialogTarget.showModal()
  }
}
