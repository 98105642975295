const TurboHelper = class {
  constructor() {
    this.removeHubspotChatScript();
  }

  // This removes a HS script from the <head> of the page related to initializing the chat.
  // When navigating with turbo, the <body> of the page is replaced, however the <head> is not. So the HS script
  // in the <body> does re-execute but finds that the script in the <head> already exists on the page, so does not
  // re-add it, therefore this does not execute and the chat is not re-initialized in the new <body>.
  // Running this before turbo renders the new <body> restores the page to a state that allows the chat to be
  // correctly re-initialized.
  removeHubspotChatScript = () => {
    document.addEventListener('turbo:before-render', () => {
      document.querySelector('#hubspot-messages-loader').remove();
      window.hubspot_live_messages_running = false
    })
  };
}

export default new TurboHelper();
