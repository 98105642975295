import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize () {
    this.open = false
  }

  connect () {
    this.mask = document.querySelector('.Sidebar-mask')
    if (this.mask) {
      this.mask.onclick = () => {
        this.toggleMenu()
      }
    }
  }

  toggleMenu () {
    this.open = !this.open
    const sidebarLeft = document.querySelector('.Sidebar--left')
    if (sidebarLeft) {
      if (this.open) {
        sidebarLeft.classList.add('is-visible')
        this.mask.classList.add('is-visible')
      } else {
        sidebarLeft.classList.remove('is-visible')
        this.mask.classList.remove('is-visible')
      }
    }
  }
}
