import { I18n } from 'i18n-js'
import translations from '../translations.json'

// Setup translations
const i18n = new I18n(translations)
i18n.defaultLocale = window.provenance.locale || 'en'
i18n.locale = window.provenance.locale || 'en'

// Wrap the standard "t" function provided by the i18n library so it can
// be imported standalone and used without a "i18n." prefix.
const t = (string, params) => i18n.t(string, params)

export default t
