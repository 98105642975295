// Takes a URLSearchParams object and assigns each parameter to an object, merging
// values with matching keys to one comma seperated string value
export function assignParamsObject (params) {
  const paramsObject = {}

  params.forEach(function (val, key) {
    if (paramsObject[key] !== undefined) {
      paramsObject[key] = paramsObject[key].concat(',' + val)
    } else {
      paramsObject[key] = val
    }
  })
  return paramsObject
}
