import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'name',
    'componentType',
    'material',
    'mainComponent',
    'submit'
  ]

  connect () {
    this.validateForm()
  }

  validateForm () {
    if (this.namePresent() &&
      this.componentTypesPresent() &&
      this.materialsPresent() &&
      this.mainComponentPresent()) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  namePresent () {
    return !!this.nameTarget.value
  }

  componentTypesPresent () {
    let componentTypesPresent = false

    for (const ct of this.componentTypeTargets) {
      componentTypesPresent = !!ct.value && !ct.hidden
      if (componentTypesPresent === false) { break }
    }

    return componentTypesPresent
  }

  materialsPresent () {
    let materialsPresent = false

    for (const m of this.materialTargets) {
      materialsPresent = !!m.value && !m.hidden
      if (materialsPresent === false) { break }
    }

    return materialsPresent
  }

  mainComponentPresent () {
    const mainValues = []

    for (const main of this.mainComponentTargets) {
      mainValues.push(main.value === 'true')
    }

    return mainValues.filter(Boolean).length === 1
  }

  disableSubmit (event) {
    if (event.keyCode === 13) {
      event.preventDefault()
    }
  }
}
