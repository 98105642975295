import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'submitSelected',
    'packagingSetRadio'
  ]

  connect () {
    this.enableSelectPackagingBtn()
  }

  enableSelectPackagingBtn () {
    let selected = false

    for (const radio of this.packagingSetRadioTargets) {
      selected = radio.checked
      if (selected === true) { break }
    }

    if (selected) {
      this.submitSelectedTarget.disabled = false
    }
  }
}
