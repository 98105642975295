import { Controller } from '@hotwired/stimulus'

// Disable this until it resolved how to make it work reliably
// import { loadChargebee } from 'chargebee-js'


export default class extends Controller {
  static get targets () {
    return ['monthly', 'annually']
  }

  // connect() {
  //   // console.log('Billing controller connected', provenance.chargebeeSite)
  //   loadChargebee({ site: provenance.chargebeeSite })
  // }

  toggle(e) {
    switch(e.srcElement.id) {
      case "freq_monthly":
        this.hide(this.annuallyTarget);
        this.show(this.monthlyTarget);
        break;
      case "freq_annually":
        this.hide(this.monthlyTarget);
        this.show(this.annuallyTarget);
        break;
    }
  }

  show(element) {
    element.style.display = 'block'
  }

  hide(element) {
    element.style.display = 'none'
  }
}
