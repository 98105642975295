import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['default', 'recyclablePackaging', 'vegan', 'veganTextIngredients']

  static values = {
    veganFlag: Boolean,
    veganTextIngredientsFlag: Boolean,
    schemeSlug: String
  }

  initialize () {
    this.defaultTarget.hidden = true
    this.veganTarget.hidden = true
    this.veganTextIngredientsTarget.hidden = true
    this.recyclablePackagingTarget.hidden = true
  }

  connect () {
    console.log('this.veganTextIngredientsFlagValue', this.veganTextIngredientsFlagValue)
    switch (this.schemeSlugValue) {
      // When vegan scheme
      case 'vegan':
        // Don't show any wizard if vegan evidencing wizard switched off
        if (!this.veganFlagValue) {
          this.defaultTarget.hidden = false
          break
        // Show vegan text ingredients flow if relevant for this claim
        } else if (this.veganTextIngredientsFlagValue) {
          this.veganTextIngredientsTarget.hidden = false
          break
        // Otherwise, show the normal vegan evidencing wizard
        } else {
          this.veganTarget.hidden = false
          break
        }
      case 'partly-recyclable-packaging':
      case 'widely-recyclable-packaging':
        this.recyclablePackagingTarget.hidden = false
        break
      default:
        this.defaultTarget.hidden = false
    }
  }

  displayDefault () {
    this.recyclablePackagingTarget.hidden = true
    this.veganTarget.hidden = true
    this.defaultTarget.hidden = false
  }
}
