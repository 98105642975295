import { Controller } from '@hotwired/stimulus'
import { createGrid } from 'ag-grid-community'

export default class extends Controller {
  static get targets () {
    return ['table']
  }

  static values = {
    column: String,
    columnOptions: String,
    row: String
  }

  connect() {
    const columnOptions = JSON.parse(this.columnOptionsValue);
    const columnDefs = [
      { field: 'id', hide: true },
      {
        headerName:"",
        field:"tree",
        hide: (columnOptions.hidden_columns && columnOptions.hidden_columns.includes('tree')),
        cellRenderer: params => {
          if (params.data.type.includes('::Group')) {
            const eDiv = document.createElement('div');
            eDiv.innerHTML = `<span id="product-chevron-${params.data.id}" class="ag-icon ag-icon-tree-closed"></span>`;
            const eButton = eDiv.querySelectorAll('.btn-simple')[0];

            return eDiv;
          }
        }
      },
      {
        headerName:"Name",
        field:"name",
        filter: "agTextColumnFilter",
        headerCheckboxSelection: columnOptions.show_checkbox,
        checkboxSelection: columnOptions.show_checkbox,
        showDisabledCheckboxes: true,
        hide: (columnOptions.hidden_columns && columnOptions.hidden_columns.includes('name')),
        minWidth: 275,
        maxWidth: 360,
        valueGetter: (p) => p.data.name.text,
        tooltipValueGetter: (p) => p.data.name.text,
        cellRenderer: params => {
          const eDiv = document.createElement('div');
          if (params.data.name.link) {
            eDiv.innerHTML = `<a href="${params.data.name.link}" class="">${params.data.name.text}</a>`;
          } else {
            eDiv.innerHTML = `<p>${params.data.name.text}</p>`
          }
          const eButton = eDiv.querySelectorAll('.btn-simple')[0];

          return eDiv;
        }
      },
      {
        headerName:"SKU",
        field:"sku",
        minWidth: 120,
        filter: "agTextColumnFilter",
        hide: (columnOptions.hidden_columns && columnOptions.hidden_columns.includes('sku'))
      },
      {
        headerName:"GTIN",
        field:"gtin",
        minWidth: 130,
        filter: "agTextColumnFilter",
        hide: (columnOptions.hidden_columns && columnOptions.hidden_columns.includes('gtin'))
      },
      {
        headerName:"Existing Proof Points",
        field:"proof_points",
        minWidth: 300,
        maxWidth: 300,
        autoHeight: true,
        filter: "agTextColumnFilter",
        wrapText: true,
        hide: (columnOptions.hidden_columns && columnOptions.hidden_columns.includes('proof_points')),
        cellRenderer: params => {
          if (params.data.proof_points) {
            let schemeDiv = document.createElement('div');
            params.data.proof_points.forEach(function(scheme) {
              const eDiv = document.createElement('div');
              eDiv.classList.add('horizontal', 'flex-wrap');
              eDiv.innerHTML = `<div class="ClaimCapsule mr-2 mt-2 ClaimCapsule--small">
                <h2 class="ClaimCapsule-title ClaimCapsule-title--small">${scheme}</h2>
              </div>`;

              schemeDiv.innerHTML += eDiv.innerHTML;
            });

            return schemeDiv;
          }
        }
      },
      {
        headerName:"Ingredients",
        field:"ingredients",
        minWidth: 250,
        maxWidth: 250,
        autoHeight: true,
        wrapText: true,
        hide: (columnOptions.hidden_columns && columnOptions.hidden_columns.includes('ingredients')),
        cellRenderer: (params) => {
          const eDiv = document.createElement('div');
          eDiv.classList.add('d-flex', 'flex-justify-start', 'flex-items-center', 'horizontal', 'flex-wrap');
          eDiv.innerHTML = `<div>${params.data.ingredients}</div>`;
          return eDiv;
        },
      },
      {
        headerName:"Channel",
        field:"channel",
        filter: "agTextColumnFilter",
        hide: (columnOptions.hidden_columns && columnOptions.hidden_columns.includes('channel'))
      },
    ]
    const agGridElement = this.tableTarget;
    const gridOptions = {
      rowData: this.intersperseChildren(JSON.parse(this.rowValue)),
      columnDefs: columnDefs,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      suppressServerSideFullWidthLoadingRow: true,
      groupDisplayType: 'custom',
      domLayout: 'autoHeight',
      pagination: columnOptions.pagination,
      paginationPageSize: 20,
      paginationPageSizeSelector: [10, 20, 50, 100],
      onGridReady(event){
        event.api.forEachNode((rowNode, index) => {
          if (columnOptions.selected_rows && columnOptions.selected_rows.includes(rowNode.data.id)) {
            rowNode.setSelected(true);
            if (rowNode.data.parent_id) {
              event.api.getRowNode(rowNode.data.parent_id).setSelected(true);
            }
          }
        });
      },
      getRowId: (params) => params.data.id,
      suppressMenuHide: true,
      onRowSelected: (event) => {
        // This ensures that when a group is selected, all of its children are also selected
        // and vice versa.
        if (event.source === 'checkboxSelected') {
          if (event.node.data.type.includes('::Group')) {
            if (event.node.isSelected()) {
              event.node.data.product_children.forEach(child => {
                event.api.getRowNode(child.id).setSelected(true);
              });
            } else {
              event.node.data.product_children.forEach(child => {
                event.api.getRowNode(child.id).setSelected(false);
              });
            }
          }
        }
      },
      onSelectionChanged: (event) => {
        // This sets the value of the selected rows to a hidden input
        // for the form submit.
        let selectRows = event.api.getSelectedRows();
        if (selectRows.length == 0) {
          document.querySelector("#selectedRows").value = '';
          return;
        } else {
          let selectedRowIds = selectRows.map(row => row.id);
          let selectedRowsString = selectedRowIds.join(', ');

          document.querySelector("#selectedRows").value = selectedRowsString;
        }
      },
      isExternalFilterPresent: () => {return true;},
      doesExternalFilterPass: (node) => {
        // This stops any children of a group from being displayed until the group is clicked
        return node.data.isVisible;
      },
      onCellClicked: (event) => {
        // This toggles the visibility of the children of a group when the group is clicked

        if (event.colDef.field === 'tree') {
            document.querySelector(`#product-chevron-${event.data.id}`).classList.toggle('ag-icon-tree-closed');
            document.querySelector(`#product-chevron-${event.data.id}`).classList.toggle('ag-icon-tree-open');
            event.api.forEachNode((rowNode, index) => {
              if (rowNode.data.parent_id == event.data.id) {
                if (rowNode.data.isVisible) {
                  rowNode.data.isVisible = false;
                } else {
                  rowNode.data.isVisible = true;
                }
              }
            });
          event.api.onFilterChanged()
        }
      },
    };

    if (columnOptions.fit_full_width) {
      gridOptions.autoSizeStrategy = {
        type: 'fitGridWidth',
        defaultMinWidth: 120,
        columnLimits: [
          {
            colId: 'name',
            minWidth: 900
          }
        ]
      }
    } else {
      console.log('fit cell contents')
      gridOptions.autoSizeStrategy = {
        type: 'fitCellContents'
      }
    }

    createGrid(agGridElement, gridOptions);
  }

  disconnect() {
    this.gridApi.destroy();
  }

  intersperseChildren(products) {
    // Due to the way the data is structured, we need to manually add the children of each product
    // to the array of products so that they are displayed in the grid.
    return products.reduce((acc, product) => {
      // Add the current product
      acc.push(product);

      // Add all children of the current product (if any)
      if (product.product_children && product.product_children.length > 0) {
          acc.push(...product.product_children);
      }

      return acc;
    }, []);
  }
}
