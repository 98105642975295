import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    if (!localStorage.getItem('provenance_cookies')) {
      this.element.classList.add('is-visible')
    }
  }

  close () {
    this.element.classList.remove('is-visible')
    localStorage.setItem('provenance_cookies', true)
  }
}
