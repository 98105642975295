import { Controller } from '@hotwired/stimulus'
import MicroModal from 'micromodal'

/**
 * Manages alert dialogs for warnings and confirmations.
 */
export default class AlertController extends Controller {
  // TODO: REPLACE WITH HUBSPOT
  // submitContact (event) {
  //   this.cancel(event)
  //   const message = 'I would like to delete a connection'
  //   Intercom('showNewMessage', message)
  // }

  hide () {
    this.element.classList.remove('is-visible')
  }

  submitToggle (event) {
    const checkboxId = event.target.dataset.alertCheckboxId
    const checkboxElement = document.querySelector(`#${checkboxId}`)
    checkboxElement.checked = !checkboxElement.checked
    this.cancel(event)
    this.getSortLanguagesController().updateDefaultLabel()
  }

  cancel (event) {
    event.preventDefault()
    event.stopPropagation()

    MicroModal.close('alert-modal')
  }
}
