import { Controller } from '@hotwired/stimulus'
import Choices from 'choices.js'
import Sortable from 'sortablejs/modular/sortable.core.esm'
import { internalLog } from '../../utils/logger'

export default class extends Controller {
  static targets = ['select', 'deleteButton', 'addButton', 'list', 'sortMessage', 'failureMessage', 'userInputList', 'submit']
  static values = { url: String }

  connect () {
    this.choices = {}
    this.selectTargets.forEach(selectElement => this.initChoices(selectElement))
    this.initListSort()
  }

  delete (e) {
    if (this.listTarget.children.length === 1) return
    const liToRemove = e.currentTarget.closest('li')
    liToRemove.remove()
    this.updateIds()
    this.updateListComponentInput()
    this.checkForAllMatching()
  }

  confirmDelete (e) {
    e.preventDefault()
    if (this.listTarget.children.length === 1) return alert('Sorry, there must be at least one ingredient in the list!')
    if (confirm('Are you sure?')) this.delete(e)
  }

  add () {
    const ul = this.listTarget
    const lastLi = ul.lastElementChild
    const clonedLi = this.createNewLi(lastLi)

    ul.appendChild(clonedLi)
    const clonedOption = clonedLi.querySelector('option')
    clonedOption.value = ''
    clonedOption.innerText = ''
    this.sortMessageTarget.style.display = 'block'
    this.updateIds()
    this.initChoices(clonedLi.querySelector('select'))
    const deleteButton = clonedLi.querySelector(':has(.octicon-trash)')
    deleteButton.addEventListener('click', (event) => this.confirmDelete(event))
    this.updateListComponentInput()
    this.checkForAllMatching()
  }

  search (query, targetId) {
    const url = this.urlValue
    fetch(`${url}?query=${query}`)
      .then(response => response.json())
      .then(data => {
        this.choices[targetId].setChoices(data, 'value', 'label', true)
      })
      .catch(e => {
        internalLog(e)
      })
  }

  initChoices (selectElement) {
    this.choices[selectElement.id] = new Choices(selectElement, {
      searchFields: ['label'],
      searchEnabled: true,
      searchResultLimit: 10,
      itemSelectText: '',
      searchPlaceholderValue: ''
    })
    this.addSelectListeners(selectElement)
  }

  addSelectListeners (selectElement) {
    selectElement.addEventListener('search', (e) => {
      if (e.detail.value.length > 2) {
        this.search(e.detail.value, e.target.id)
      }
    })
    selectElement.addEventListener('change', () => this.optionSelect(selectElement))
  }

  optionSelect (selectElement) {
    const li = selectElement.closest('.IngredientsList-listItem')
    li.querySelector('.octicon-x-circle').style = 'display: none;'
    li.querySelector('.octicon-check-circle').style = ''
    selectElement.dataset.match = 'true'
    this.updateListComponentInput()
    this.checkForAllMatching()
  }

  updateIds () {
    const liElems = document.querySelectorAll('.IngredientsList-listItem[id]')
    liElems.forEach((elem, index) => { elem.id = `${index}` })
  }

  checkForAllMatching () {
    const nonMatchingArr = this.selectTargets.filter(select => select.dataset.match === 'false')
    const submitButton = this.submitTarget
    if (nonMatchingArr.length !== 0 || this.selectTargets.length === 0) {
      submitButton.disabled = true
      this.failureMessageTarget.style.display = 'block'
    } else {
      submitButton.disabled = false
      this.failureMessageTarget.style.display = 'none'
    }
  }

  initListSort () {
    const list = this.listTarget
    Sortable.create(list, {
      onEnd: (evt) => {
        if (evt.oldIndex !== evt.newIndex) {
          this.updateIds()
          this.updateListComponentInput()
        }
      }
    })
  }

  updateListComponentInput () {
    const ingredientsInput = document.querySelector('input#ingredient_refs')
    const liElems = document.querySelectorAll('.IngredientsList-listItem[id]')
    const values = [...liElems].map((elem) => elem.querySelector('option').innerText)
    if (ingredientsInput) {
      ingredientsInput.value = values.join('|')
    }
  }

  createNewLi (lastLi) {
    const clonedLi = lastLi.cloneNode(true)
    const clonedSelect = clonedLi.querySelector('select')
    const clonedSelectDiv = clonedLi.querySelector('.IngredientsList-ingredientValue')

    clonedSelectDiv.replaceChildren(clonedSelect)

    clonedLi.querySelector('.IngredientsList-userInput').innerText = 'New Ingredient'
    clonedSelect.dataset.choice = ''
    clonedSelect.dataset.match = 'false'

    clonedLi.querySelector('.octicon-x-circle').style = ''
    clonedLi.querySelector('.octicon-check-circle').style = 'display: none;'

    return clonedLi
  }
}
