import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ["source", "destination"]

    connect() {
        this.initialSourceValue = this.sourceTarget.value;

        if(this.initialSourceValue) {
            this.destinationTarget.innerText = this.initialSourceValue;
        }
    }

    copy(event) {
        this.destinationTarget.innerText = event.currentTarget.value;
    }
}
